<div class="wrapper">
  <h3 class="modal-heading make-space-for-close-button">Terms of Use</h3>
  <div class="content">
    <p>
      DeakinSync is for authorised access only. Any attempt to log-in using the
      username and password of another student is prohibited.
    </p>
    <p>
      The University reserves the right to monitor access to and use of
      DeakinSync and take disciplinary action if unauthorised access is
      attempted or use of DeakinSync does not comply with the
      <a
        href="https://policy.deakin.edu.au/view.current.php?id=133"
        target="_blank"
        >Information and Communications Technology Acceptable Use policy</a
      >, and its related procedures.
    </p>
  </div>
  <button class="close-button" aria-label="Close" (click)="close()">
    <span class="far fa-times" aria-hidden="true"></span>
  </button>
</div>
