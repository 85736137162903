import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize, endWith, filter } from 'rxjs/operators';
import { Injector } from '@angular/core';

import { TrackingIdsService } from '../tracking-ids.service';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService {
  constructor(private trackingIdService: TrackingIdsService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const request = req.clone({});

    return next.handle(req).pipe(
      filter((event) => event instanceof HttpResponse),
      tap((res: HttpResponse<any>) => {
        if (res.headers) {
          const trackingId = res.headers.get('x-trackingid');
          const responseStatus = res.status;
          const requestPath = this.getPathFromUrl(request.url);
          this.trackingIdService.saveTrackingId(
            requestPath,
            responseStatus,
            trackingId
          );
        }
      })
    );
  }

  /**
   * Returns the path fragment after the last '/' in the url
   * @param {string} url
   */
  private getPathFromUrl(url: string): string {
    return url.slice(url.lastIndexOf('/') + 1, url.length + 1);
  }
}
