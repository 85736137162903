import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppConnectService } from '../../services/auth/appconnect-oauth.service';
import { Subscription } from 'rxjs';
import { SessionStorageService } from '../../services/session-storage.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {
  private isDoneLoadingSubscription$: Subscription;
  // Flag to automatically redirect user to identity provider when they are not logged in
  private autoInitLoginFlow = false;
  blurbDisplayStyle = 'none';

  constructor(
    private appConnectService: AppConnectService,
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit() {
    this.isDoneLoadingSubscription$ = this.appConnectService.isDoneLoading$.subscribe(
      (_val) => {
        if (this.appConnectService.hasValidAccessToken()) {
          const redirectUrl = this.sessionStorageService.getKey('redirectUrl')
            ? this.sessionStorageService.getKey('redirectUrl')
            : { value: null };
          console.info(
            `Redirect url retrieved from SessionStorage ${redirectUrl.value}`
          );
          this.sessionStorageService.removeKey('redirectUrl');
          console.info('Landing page leaving to home page.');
          this.router.navigateByUrl(redirectUrl.value || '/home');
        } else {
          console.info('No valid access token.');
          // Show temp blurb, or start login flow
          // this.autoInitLoginFlow
          //   ? this.appConnectService.initLoginFlow()
          //   : (this.blurbDisplayStyle = 'block');
          this.appConnectService.initLoginFlow();
        }
      }
    );
  }

  // TODO: Unsubscribe on page un load
  ngOnDestroy() {
    this.isDoneLoadingSubscription$.unsubscribe();
  }

  login() {
    this.appConnectService.initLoginFlow();
  }
}
