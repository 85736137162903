import { Component, OnInit } from '@angular/core';
import { AppConnectService } from './services/auth/appconnect-oauth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { filter, map } from 'rxjs/operators'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public routeLoaded = false;

  constructor(
    private authService: AppConnectService,
    private router: Router,
    private titleService: Title
    ) {
    this.authService.runInitialLoginSequence().subscribe({
      error(e) {
        // Fatal error encountered when initializing OAuth. App needs to handle this
        console.error(`Error encountered on runInitialLoginSequence`, e);
      }
    });
  }

  appTitle = 'DeakinSync';

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((pageTitle: string) => {
        if (pageTitle) {
          this.titleService.setTitle(`${this.appTitle} | ${pageTitle}`);
        }
      });
  }

  onActivate() {
    this.routeLoaded = true;
  }
}
