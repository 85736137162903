import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  constructor() {}

  public getKey(key: string): any {
    const value = sessionStorage.getItem(key);
    if (this.IsJsonString(value)) {
      return JSON.parse(value);
    }
    return value;
  }

  public setKey(key: string, value: string): void {
    const object = { value: value, timestamp: new Date().toISOString() };
    return sessionStorage.setItem(key, JSON.stringify(object));
  }

  public removeKey(key: string): void {
    return sessionStorage.removeItem(key);
  }

  public IsJsonString(value: string): boolean {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  }
}
