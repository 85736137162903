import { OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';
import {
  Observable,
  BehaviorSubject,
  ReplaySubject,
  combineLatest
} from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AppConfigService } from '../app-config/app-config.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConnectService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  private isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public canActivateprotectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map((v) => v.every((b) => b)));

  constructor(
    private oAuthService: OAuthService,
    private appConfigService: AppConfigService
  ) {
    // Construct the authConfig to initialize the plugin with
    const authConfig = {
      issuer: this.appConfigService.authConfig.issuer,
      redirectUri: window.location.origin + '/',
      clientId: this.appConfigService.authConfig.clientId,
      responseType: 'code', // Use PKCE for public clients
      scope: this.appConfigService.authConfig.scope,
      requireHttps: this.appConfigService.authConfig.requireHttps,
      requestAccessToken: this.appConfigService.authConfig.requestAccessToken,
      showDebugInformation: !environment.production,
      redirectUriAsPostLogoutRedirectUriFallback: false
    };

    this.oAuthService.configure(authConfig);
    this.oAuthService.setupAutomaticSilentRefresh();

    this.oAuthService.events.subscribe(this.oAuthServiceEventListener);
  }

  /**
   * Event handler to deal with events emitted
   * @param { OAuthEvent } event
   */
  private oAuthServiceEventListener = (event) => {
    if (event instanceof OAuthErrorEvent) {
      console.warn(`OAuth Error Event received. Error Type: ${event.type}`);
    }
    this.isAuthenticatedSubject$.next(this.oAuthService.hasValidAccessToken());
  };

  /**
   * Used to establish the connection to the OAuth identity server
   * has to be called once towards the beginning of app load
   */
  public runInitialLoginSequence(): Observable<boolean> {
    return new Observable((observer) => {
      // Try to log in using the "?code=" parameter in the URL
      this.oAuthService
        .loadDiscoveryDocumentAndTryLogin()
        .then(() => {
          if (!this.oAuthService.hasValidAccessToken()) {
            // tryLogin did not error, but a valid access_token was not found
            // This will happen when user is yet to login, or access_token is expired
            console.info(
              'OAuthService:  No valid access token was found after oAuthService.tryLogin(). User Login required!'
            );
          }
          observer.complete();
          this.isDoneLoadingSubject$.next(true);
        })
        .catch((error) => {
          this.isDoneLoadingSubject$.next(true);

          // One of the promises above failed, possibly oAuthService.tryLogin()
          if (this.oAuthService.hasValidAccessToken()) {
            // A valid access_token exists in sessionStorage
            console.info(
              'runInitialLoginSequence failed, but a valid access token exists'
            );
            return observer.complete();
          } else {
            return observer.error(error);
          }
        });
    });
  }

  public getTokenReceivedEvent(): Observable<any> {
    return this.oAuthService.events.pipe(
      filter((event) => event.type.toString() === 'token_received')
    );
  }

  /**
   * Redirects user to the identity provider to initiate code flow
   */
  public initLoginFlow = () => this.oAuthService.initCodeFlow();

  public logOut = () => {
    this.oAuthService.revokeTokenAndLogout({ post_logout_redirect_uri: '' });
  };

  public hasValidAccessToken = () => this.oAuthService.hasValidAccessToken();
  public getAccessToken = () => this.oAuthService.getAccessToken();
}
