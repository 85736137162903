import { Injectable } from '@angular/core';
import { ITrackingData } from '../interfaces/iTrackingData';

@Injectable({
  providedIn: 'root'
})
export class TrackingIdsService {
  private trackingIds: Record<string, ITrackingData[]> = {};

  get trackingIdTable(): Record<string, ITrackingData[]> {
    return this.trackingIds;
  }

  constructor() {}

  public saveTrackingId(path: string, httpStatus: number, trackingId: string) {
    const trackingData: ITrackingData = { httpStatus };
    trackingData.trackingId = trackingId;

    if (!(path in this.trackingIdTable)) {
      this.trackingIds[path] = [trackingData];
    } else {
      this.trackingIds[path].push(trackingData);
    }
  }

  public getTrackingIdForPath(path: string): string {
    if (!(path in this.trackingIdTable)) {
      return undefined;
    }
    const lastTrackingData: ITrackingData = this.trackingIdTable[path].slice(
      -1
    )[0];
    return lastTrackingData?.trackingId;
  }
}
