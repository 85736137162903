import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome-placeholder',
  templateUrl: './welcome-placeholder.component.html',
  styleUrls: ['./welcome-placeholder.component.scss']
})
export class WelcomePlaceholderComponent implements OnInit {
  ngOnInit() {}
}
