import {
  Component,
  OnInit,
  Input
} from '@angular/core';

@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss']
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
})

/**
 * Component will be created by the placeholder directive
 * (src/app/shared/directives/placeholder.directive.ts)
 *
 * Building block of the components are in Design System placeholder components:
 * https://ucd.deakin.edu.au/components-ref/?path=/story/global-components-placeholder--playground
 */
export class PlaceholderComponent implements OnInit {
  @Input()
  placeholder = '';

  constructor() {}

  ngOnInit() {}
}
