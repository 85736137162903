import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS
} from '@angular/material/dialog';

import { AppComponent } from './app.component';
import { FooterComponent } from './shared/ui-components/footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { AppConfigService } from './services/app-config/app-config.service';
import { authConfigFactory } from './factories/oauth-module-config.factory';
import { SharedModule } from './shared/shared.module';
import { matDialogOptions } from './scss/material/mat-dialog.options';
import { TermsOfUseComponent } from './shared/modal-content/terms-of-use/terms-of-use.component';
import { DirectivesModule } from './shared/directives/directives.module';
import { SimpleFooterComponent } from '@esolutions/design-system-ng/components';

@NgModule({
  declarations: [AppComponent, TermsOfUseComponent, FooterComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    DirectivesModule,
    OAuthModule.forRoot(),
    SharedModule,
    NoopAnimationsModule,
    MatDialogModule,
    SimpleFooterComponent
  ],
  providers: [
    {
      provide: OAuthModuleConfig,
      useFactory: authConfigFactory,
      deps: [AppConfigService]
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: matDialogOptions },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {}
