<ds-skeleton
  type="heading"
  level="1"
  [rows]="1"
  length="xsmall"
  [showAnimation]="true"
>Loading...</ds-skeleton>

<ds-skeleton
  type="body"
  level="1"
  [rows]="1"
  length="medium"
  [showAnimation]="true"
>Loading...</ds-skeleton>

<div class="current-layout">
<div class="ds-layout-grid ds-layout-grid-2col">
    <ds-skeleton
      type="card-landscape"
      [showAnimation]="true"
    ></ds-skeleton>

    <ds-skeleton
      type="card-landscape"
      [showAnimation]="true"
    ></ds-skeleton>
  </div>
</div>
