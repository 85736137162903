import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Modules and components for routed features
import { LandingModule } from './pages/landing/landing.module';
import { PageNotFoundComponent } from './shared/ui-components/page-not-found/page-not-found.component';
import { ApiInterceptorService } from './services/interceptor/api-interceptor.service';

// Other routes are defined in the respective page modules
const appRoutes: Routes = [
  {
    path: 'logout',
    loadChildren: () => {
      return import('./logout/logout.module').then((m) => {
        return m.LogoutModule;
      });
    }
  },
  {
    path: 'home',
    loadChildren: () => {
      console.info('Home route initiated.');
      return import('./home/home.module').then((m) => {
        return m.HomeModule;
      });
    }
  },
  {
    path: 'offer',
    loadChildren: () => {
      return import('./offer/offer.module').then((m) => {
        return m.OfferModule;
      });
    }
  },
  {
    path: 'other',
    loadChildren: () => {
      return import('./other/other.module').then((m) => {
        return m.OtherModule;
      });
    }
  },
  {
    path: 'error',
    loadChildren: () => {
      console.info('Error route initiated.');
      return import('./error/error.module').then((m) => {
        return m.ErrorModule;
      });
    }
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      title: 'Page not found'
    }
  }
];

@NgModule({
  declarations: [PageNotFoundComponent],
  imports: [CommonModule, LandingModule, RouterModule.forRoot(appRoutes, {})],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true
    }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
