import { MatDialogConfig } from '@angular/material/dialog';
/**
 * Overide the default programmatic values so that the dialog
 * can be styled responsively with custom CSS
 */
const options: MatDialogConfig = new MatDialogConfig();
options.maxWidth = '100vw';
options.maxHeight = '100vv';

export const matDialogOptions = options;
