<ng-container>
  <ng-container>
    <div class="article-grid hero-article">
      <ds-skeleton
        type="card-landscape"
        [showAnimation]="true"
      ></ds-skeleton>
    </div>

    <div class="article-grid featured">
      <ng-container *ngFor="let i of [1, 2, 3]">
        <ds-skeleton
          type="card"
          [showAnimation]="true">
        </ds-skeleton>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
