<ng-container *ngFor="let i of [1, 2]">
  <div class="sync-grid">
    <ng-container *ngFor="let i of [1, 2, 3, 4]">
      <ds-skeleton
        type="card"
        [showAnimation]="true">
      </ds-skeleton>
    </ng-container>
  </div>
</ng-container>
