<h3 class="ds-a11y-sr-only">Loading</h3>

<div class="loading" *ngIf="placeholder === 'home-panel'">
  <app-home-placeholder></app-home-placeholder>
</div>

<div class="loading" *ngIf="placeholder === 'introexp'">
  <app-introexp-placeholder></app-introexp-placeholder>
</div>

<div class="loading" *ngIf="placeholder === 'welcome-panel'">
  <app-welcome-placeholder></app-welcome-placeholder>
</div>

<div class="loading" *ngIf="placeholder === 'my-learning'">
  <app-my-learning-placeholder></app-my-learning-placeholder>
</div>

<div class="loading" *ngIf="placeholder === 'feature'">
  <app-featured-placeholder></app-featured-placeholder>
</div>

<div class="loading" *ngIf="placeholder === 'news'">
  <app-news-placeholder></app-news-placeholder>
</div>

<div
  class="loading row"
  *ngIf="
    placeholder === 'text-only' ||
    placeholder === '' ||
    placeholder === 'card-landscape' ||
    placeholder === 'units'
  "
>
  <ds-skeleton
    type="card-landscape"
    [showAnimation]="true"
    *ngIf="placeholder === '' || placeholder === 'card-landscape'"
  ></ds-skeleton>

  <div class="container text-only" *ngIf="placeholder === 'text-only'">
    <ds-skeleton
      type="heading"
      level="3"
      [rows]="1"
      length="medium"
      [showAnimation]="true"
      class="title"
    ></ds-skeleton>

    <ds-skeleton
      type="body"
      [rows]="1"
      length="small"
      [showAnimation]="true"
      class="shorttext"
    ></ds-skeleton>

    <ds-skeleton
      type="body"
      [rows]="1"
      length="large"
      [showAnimation]="true"
      class="longtext"
    ></ds-skeleton>
  </div>
  <div class="col-small-phone-12 units" *ngIf="placeholder === 'units'">
    <div class="container row">
      <ng-container *ngFor="let i of [1, 2, 3, 4]">
        <ds-skeleton
          type="card"
          [showAnimation]="true">
        </ds-skeleton>
      </ng-container>
    </div>
  </div>
</div>
