import { Component, Input, OnInit } from '@angular/core';
import { TermsOfUseComponent } from '../../modal-content/terms-of-use/terms-of-use.component';
import { MatDialog } from '@angular/material/dialog';
import { DsGeneralNavItem } from '@esolutions/design-system-ng/models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  popup = false;
  message: string;
  links: DsGeneralNavItem[];

  @Input()
  public delay = 0;

  constructor(private matDialog: MatDialog) {
    this.message =
      'We acknowledge the Traditional Custodians of the unceded lands and waterways on which Deakin University does business. We pay our deep respect to the Ancestors and Elders of Wadawurrung Country, Eastern Maar Country and Wurundjeri Country where our physical campuses are located. We also acknowledge all First Nations Peoples that make contributions to our learning communities.';
    this.links = [
      {
        text: 'Copyright',
        route: 'https://www.deakin.edu.au/copyright'
      },
      {
        text: 'Disclaimer',
        route: 'https://www.deakin.edu.au/web-disclaimer'
      },
      {
        text: 'Privacy',
        route: 'https://www.deakin.edu.au/footer/privacy'
      },
      {
        text: 'Terms of Use',
        route: 'terms-of-use'
      }
    ];
  }

  ngOnInit() {
    if (this.delay > 0) {
      setTimeout(() => {
        this.delay = 0;
      }, this.delay);
    }
  }

  openTermsModal() {
    this.matDialog.open(TermsOfUseComponent, {
      autoFocus: false,
      width: '528px',
      maxHeight: '100vh',
      panelClass: 'custom-dialog-container'
    });
  }

  selectedLink(url: string) {
    if (url.includes('terms-of-use')) {
      this.openTermsModal();
    } else {
      window.open(url, '_blank');
    }
  }
}
