import { Injectable, Inject } from '@angular/core';
import { IAppConfig, IAuthConfig } from '../../interfaces/iAppConfig';
import { APP_CONFIG } from './injector';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  constructor(@Inject(APP_CONFIG) private config: IAppConfig) {}

  public get apiUrl(): string {
    return this.config.apiUrl;
  }

  public get apiVersion(): string {
    return this.config.apiVersion;
  }

  public get apiBasePath(): string {
    return `${this.config.apiUrl}/${this.apiVersion}`;
  }

  public get authConfig(): IAuthConfig {
    return this.config.authConfig;
  }

  public get d2lDomain(): string {
    return this.config.d2lDomain;
  }

  public get loggerUrl(): string {
    return this.config.activityLoggerUrl;
  }
}
