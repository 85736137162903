import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IErrorInfo } from '../interfaces/iErrorInfo';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(private router: Router, private urlService: UrlService) {}

  public redirectError(state: IErrorInfo) {
    const returnUrl = state.originalUrl
      ? state.originalUrl
      : this.urlService.getCurrentUrl();
    this.router.navigateByUrl(`/error?returnUrl=${btoa(returnUrl)}`, {
      state: { errorInfo: { ...state, originalUrl: `${returnUrl}` } }
    });
  }
}
