<header>
  <div class="hero-image"></div>
  <div class="sync-page-container banner-content">
    <div class="row">
      <div
        class="col-small-phone-12 col-phablet-12 col-portrait-tablet-8 col-landscape-tablet-6"
      >
        <ds-skeleton
          type="heading"
          level="2"
          [rows]="1"
          length="medium"
          [showAnimation]="true"
        ></ds-skeleton>

        <p class="description">
          <ds-skeleton
            type="body"
            [rows]="1"
            length="large"
            [showAnimation]="true"
          ></ds-skeleton>
        </p>
        <div class="call-to-actions ds-button-group">
          <ng-container>
            <ds-skeleton
              type="button"
              [showAnimation]="true"
            ></ds-skeleton>

            <ds-skeleton
              type="button"
              [showAnimation]="true"
            ></ds-skeleton>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</header>
