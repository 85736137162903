<div class="placeholder-section-student">
  <section>
    <div class="ds-layout-columns">
      <ds-skeleton
        type="heading"
        level="1"
        [rows]="1"
        length="medium"
        [showAnimation]="true"
      >Loading...</ds-skeleton>

      <ds-skeleton
        type="body"
        level="1"
        [rows]="1"
        length="large"
        [showAnimation]="true"
      >Loading...</ds-skeleton>
    </div>
  </section>
</div>

<div class="placeholder-section-content">
  <section>
    <div class="ds-layout-columns"></div>
  </section>
</div>
