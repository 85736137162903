import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { AppConfigService } from '../services/app-config/app-config.service';

// Using a factory to build OAuthModuleConfig as per run time configuration
export function authConfigFactory(
  appConfigService: AppConfigService
): OAuthModuleConfig {
  const syncBffUrl = appConfigService.apiUrl;
  const activityLogBffUrl = appConfigService.loggerUrl;

  return {
    resourceServer: {
      sendAccessToken: true,
      allowedUrls: [syncBffUrl, activityLogBffUrl]
    }
  };
}
